import React, { useState } from 'react';

import './Divider.scss';

const Divider = ({
  color,
  showLoader,
  clearProject,
}: {
  color: string;
  showLoader?: boolean;
  clearProject?: any;
}) => {
  const [clear, setClear] = useState('');

  const handleClick = () => {
    if (!!clearProject) {
      setClear('--clear');
      setTimeout(() => {
        clearProject('');
        setClear('');
      }, 800);
    }
  };
  return (
    <svg
      version="1.1"
      className={!!showLoader ? 'divider loader' : 'divider'}
      viewBox={!!showLoader ? '0 0 100 19' : '0 0 100 4'}
      xmlSpace="preserve"
      onClick={handleClick}
      aria-label="Clear current project"
      role="button"
    >
      <g>
        <rect x="40" y="2" width="25" height="2" fill={color} />
        {!!showLoader && (
          <g>
            <line
              className={`divider--vertical${clear}`}
              x1="40"
              x2="40"
              y1="2"
              y2="17"
              stroke={color}
            />
            <line
              className={`divider--vertical${clear}`}
              x1="65"
              x2="65"
              y1="2"
              y2="17"
              stroke={color}
            />
            <line
              className={`divider--horizontal${clear}`}
              x1="40"
              x2="65"
              y1="16"
              y2="16"
              stroke={color}
            />
            <g className="divider--x">
              <line
                className={`divider--x--left${clear}`}
                x1="56"
                x2="50"
                y2="6.5"
                y1="12"
                stroke={color}
              />
              <line
                className={`divider--x--right${clear}`}
                x2="50"
                x1="56"
                y2="12"
                y1="6.5"
                stroke={color}
              />
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

export default Divider;
